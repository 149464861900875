import React, { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import { Navigate } from 'react-router-dom';


import "yet-another-react-lightbox/styles.css";

import './styles.scss';
import Favourites from '../../containers/Favourites';
import { Image } from './Image';

export const Gallery = ({
  gallery,
  layout,
  columns,
  setFavourite,
  deletePicture,
  shooting,
  favouritesSelected,
  user,
  isLogged,
}) => { 
  
  const [index, setIndex] = useState(-1);

  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  if(!gallery) {
    return null;
  }
  
  if(isLogged & user.firstConnect) {
    return <Navigate to="/temp" />;
  }

  gallery.sort((a, b) => a.id - b.id);

  const photos = gallery.map((photo, index) => {
    const width = photo.width;
    const height = photo.height;
    const isFavourite = photo.isFavourite;
    return {
      src: `${photo.secureUrl}`,
      key: `${index}`,
      width,
      height,
      isFavourite,
      id: `${photo.id}`,
      images: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: `${photo.secureUrl}`,
          width: breakpoint,
          height: breakpointHeight,
        };
      })
    };
  });


  const slides = photos.map(({ src, key, width, height, images }) => ({
    src,
    key,
    aspectRatio: width / height,
    srcSet: images?.map((image) => ({
      src: image.src,
      width: image.width
    }))
  }));


  return (
  
      <div className='gallery'>
        <div className='gallery__container'>
          <div className='gallery__container__photoAlbum'>
            {!user.isClient && shooting && shooting.nameOfFavourites &&
              <div>
                <p> Cette séance photo a eu lieu il y a plus de 6 mois, les photos ont été supprimées. Voici le nom des photos sélectionnées : </p> 
                <p> {shooting.nameOfFavourites} </p>
              </div>
            }
            <PhotoAlbum
              layout={layout}
              columns={columns}
              photos={photos}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
              renderPhoto={(props) => (
                <Image 
                  {...props} 
                  user={user}
                  favouritesSelected={favouritesSelected}
                  setFavourite={setFavourite}
                  deletePicture={deletePicture}
                  shooting={shooting}  
                />
              )}
            />
          </div>

          <Favourites />

        </div>

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={slides}
        />  
      </div>
  );
};

Gallery.propTypes = {
};

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_PICTURE = 'DELETE_PICTURE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_FAVOURITE = 'SET_FAVOURITE';
export const ADD_PICTURE_ON_THEME = 'ADD_PICTURE_ON_THEME';


export const uploadImageAction = (formData, shootingId, config, resolve, reject) => ({
  type: 'UPLOAD_IMAGE',
  formData,
  shootingId,
  config,
  resolve,
  reject,
});

export const deletePicture = (pictureId, shootingId) => ({
  type: 'DELETE_PICTURE',
  pictureId,
  shootingId,
});

export const setIsLoading = () => ({
  type: 'SET_IS_LOADING',
});

export const setFavourite = (pictureId) => ({
  type: 'SET_FAVOURITE',
  pictureId,
});
import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useParams } from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import './styles.scss';
import Gallery from '../../../containers/Gallery';
import AddPicture from '../../../containers/AddPicture';
import Loading from '../../../components/Loading';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export const ShootingShow = ({
  shooting,
  isLoading,
  fetchShooting,
  favouritesSelected,
  updateShooting,
  deletePicture,
  user

}) => {
  
  const [showFormUpdateShooting, setShowFormUpdateShooting] = useState(false);
  const [newNumberOfPictures, setNewNumberOfPictures] = useState(shooting.numberOfPictures);

  let {id} = useParams();

  useEffect(() => {
    fetchShooting(id);
  }, [
    fetchShooting,
    id,
  ]);

  if (Object.keys(shooting).length === 0 && shooting.constructor === Object) {
    return <Loading />;
  }

  const handleDownloadAllImages = async () => {
    const zip = new JSZip();
    const folder = zip.folder("images");
    const pictures = shooting.pictures;
    const picturesLength = pictures.length;
    for (let i = 0; i < picturesLength; i++) {
      const picture = pictures[i];
      const response = await fetch(picture.secureUrl);
      const blob = await response.blob();
      folder.file(picture.originalName, blob);
    }
    zip.generateAsync({type:"blob"})
      .then(function(content) {
        saveAs(content, "images.zip");
      });
  };

  const handleShowFormUpdateShooting = () => {
    setShowFormUpdateShooting(!showFormUpdateShooting);
  }

  const handleUpdateShooting = (evt) => {
    evt.preventDefault();
    updateShooting(id, newNumberOfPictures);
    setShowFormUpdateShooting(false);
  }

  const handleChangeNumberOfPictures = (evt) => {
    setNewNumberOfPictures(evt.target.value);
  }

  // compter le nombre de caracteres dans le nom de la galerie
  const countCharacters = (str) => {
     return str.length;
  }

  console.log(countCharacters(shooting.nameOfGallery));



  return (
    <>
      {isLoading ? (
          <Loading />
        ) : (
        
          <div className='shooting'>

            <div className='shooting__header'>
              { countCharacters(shooting.nameOfGallery) > 10 ? 
                <h2 className='shooting__header__title long'> {shooting.nameOfGallery} </h2> 
                : 
                <h2 className='shooting__header__title short'> {shooting.nameOfGallery} </h2>
              }
              <div className='shooting__header__name'> {!user.isClient && <p>{shooting.user.firstName + ' ' + shooting.user.lastName }</p>} </div>
              <div>
                {!favouritesSelected && !user.isClient && shooting.nameOfFavourites === null && 
                  <AddPicture />
                }
              </div>
              {!favouritesSelected &&
                <>
                { !user.isClient ?
                  <button className="myButton" onClick={handleShowFormUpdateShooting}>
                    {shooting.numberOfPictures} 
                  </button> 
                  :
                  <span className='shooting__header__number'>{shooting.numberOfPictures}</span>
                }
                  <span className='shooting__header__text'>photos à choisir parmi {shooting.pictures.length} proposées</span>
                </>
              }
              
              { shooting && !user.isClient && showFormUpdateShooting &&  
                <form onSubmit={handleUpdateShooting}>
                  <label htmlFor='numberOfPictures' className='me-2'>Changer le nombre de photos :</label>
                  <input type='number' name='numberOfPictures' value={newNumberOfPictures} onChange={handleChangeNumberOfPictures} min={0} />
                  <button type='submit'> OK </button>
                  <button onClick={() => setShowFormUpdateShooting(false)}> Annuler </button>
                </form> 
              } 

              { user.isClient && favouritesSelected && (
                <button className='myButton' onClick={handleDownloadAllImages}>Télécharger toutes les images</button>
              )}
            </div>
            
              <MediaQuery minWidth={769}>
                <Gallery gallery={shooting.pictures} layout={"columns"} columns={3} deletePicture={deletePicture} />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                  <Gallery gallery={shooting.pictures} layout={"columns"} columns={1} deletePicture={deletePicture}/>
              </MediaQuery>  

          </div>
        )}
    </>
  );
};

ShootingShow.propTypes = {
};

import { connect } from 'react-redux';
import { AddPicture } from '../../components/AddPicture';

import { uploadImageAction } from '../../store/actions/picture';



const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
    uploadImage: (formData, shootingId, config) => {
      return new Promise((resolve, reject) => {
        dispatch(uploadImageAction(formData, shootingId, config, resolve, reject));
      });
    },
  });

export default connect(mapStateToProps, mapDispatchToProps)(AddPicture);

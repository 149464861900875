import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

import './styles.scss';

export const AddPicture = ({
  uploadImage,
}) => {

    let {id} = useParams();

    const [showForm, setShowForm] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);

    const handleUpload = async () => {
      try {
        for (let i = 0; i < imagesToUpload.length; i++) {
          await uploadImageWithProgress(i); // Attend que l'image soit uploadée avant de passer à la suivante
        }
        Swal.fire("Succès", "Toutes les images ont été téléchargées avec succès.", "success");
        setImagesToUpload([]); 
        setShowForm(false);
      } catch (error) {
        Swal.fire("Erreur", "Une ou plusieurs images n'ont pas pu être téléchargées.", "error");
      }
    };
    
    
    const uploadImageWithProgress = async (index) => {
      const { file } = imagesToUpload[index];
      const formData = new FormData();
      formData.append('file', file);
    
      return new Promise((resolve, reject) => {
        const config = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const adjustedProgress = progress === 100 ? 99 : progress; // Limiter à 99% pendant la requête
            setImagesToUpload((prev) => {
              const updatedImages = [...prev];
              updatedImages[index] = {
                ...updatedImages[index],
                progress: adjustedProgress, // Met à jour la progression avec la valeur ajustée
              };
              return updatedImages;
            });
          },
        };
    
        uploadImage(formData, id, config)
          .then(() => {
            setImagesToUpload((prev) => {
              const updatedImages = [...prev];
              updatedImages[index] = {
                ...updatedImages[index],
                progress: 100, // Upload effectivement terminé
              };
              return updatedImages;
            });
            resolve(); // Passe à l'image suivante
          })
          .catch((err) => {
            console.error(`Échec de l'upload de l'image à l'index ${index}`, err);
            reject(err);
          });
      });
    };
    
    
     const toggleForm = () => {
      setShowForm(!showForm);
      };


      const selectImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = 'image/*';
        input.click();
      
        input.addEventListener('change', (e) => {
          const files = e.target.files;
          const newImages = Array.from(files).map((file) => ({
            file, 
            url: URL.createObjectURL(file), 
            progress: 0, 
          }));
      
          setImagesToUpload((prev) => [...prev, ...newImages]); 
        });
      };
            


  return (
  
    <div className='addPicture'>
      <button className="myButton mb-3" onClick={toggleForm}>
        <i className="bi bi-plus-lg me-2" ></i>
        <span>Ajouter des photos</span>
      </button>

      {showForm && (
        <div className='addPicture__form-container'>
          <div 
            className="myButton d-block text-center" 
            onClick={selectImage}
          >
            Sélectionner une ou plusieurs images
          </div>
          {imagesToUpload.length > 0 && <p className='my-4 text-center text-black'> {imagesToUpload.length} images sélectionnées :</p>}
          <div className='addPicture__form-container__images'>
            {imagesToUpload.map((image, index) => (
              <div key={index} className='d-flex flex-column align-items-center'>
                <div className='d-flex'>
                  <div className='text-black me-2'>{image.file.name}</div>
                  <i className='bi bi-x-circle-fill text-danger' onClick={() => setImagesToUpload((prev) => prev.filter((_, i) => i !== index))}></i>
                </div>
                <img 
                  className='addPicture__form-container__images__item' 
                  src={image.url} 
                  alt='' 
                />
                <div className='progress mt-2' style={{ width: "100%" }}>
                  <div
                    className='progress-bar bg-warning progress-bar-striped progress-bar-animated'
                    role='progressbar'
                    aria-valuenow={image.progress}
                    aria-valuemin='0'
                    aria-valuemax='100'
                    style={{ width: `${image.progress}%` }} 
                  >
                  </div>
                </div>
                { image.progress === 100 ? 
                  <i className="bi bi-check-circle-fill text-success"></i> 
                  : 
                  image.progress + '%' 
                }
              </div>
            ))}
          </div>


          {imagesToUpload.length > 0 && <button type='button' onClick={handleUpload} className='myButton d-block mt-4'>Ajouter</button>}
        </div>
      )}
    
    </div>
 
  );
};

AddPicture.propTypes = {
};
import React from 'react';
import Swal from 'sweetalert2';

import './styles.scss';


export const Image = ({ 
    imageProps: { alt, style, ...restImageProps },
    photo,
    user,
    favouritesSelected,
    setFavourite,
    deletePicture,
    shooting, 
  }) => { 

    const handleSetFavourite = (evt) => {
        setFavourite(evt.target.id, shooting.id);
    }

    const handleDownloadImage = (image) => {
        fetch(image.src)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = shooting.nameOfGallery + '-' + image.key;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Erreur lors du téléchargement de l\'image : ', error);
          });
      };

    const handleDeletePicture = (evt) => {
        Swal.fire({
            text: 'Es-tu sûr(e) de vouloir supprimer cette photo ?',
            showDenyButton: true,
            confirmButtonText: `Valider`,
            denyButtonText: `Annuler`,
            icon: 'question',
        }).then((result) => {
            if (result.isConfirmed) {
                deletePicture(evt.target.id, shooting.id);
            }
        })
    }

    return (      
    
      <div style={{ width: style?.width}} className='image'>

        <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
        
        { user.isClient && !favouritesSelected && (
            !photo.isFavourite ? (
              <button className='image__action' onClick={handleSetFavourite} id={photo.id}>
                <i className="bi bi-plus-lg image__action__icon" id={photo.id}></i>
                <span className='image__action__text' id={photo.id}> Ajouter aux favorites </span>
              </button> 
            ) : (
              <button className='image__action favorite' onClick={handleSetFavourite} id={photo.id}>
                <i className="bi bi-check-lg image__action__icon" id={photo.id}></i>
                <span className='image__action__text' id={photo.id}> Retirer des favorites </span>
              </button> 
            )
        )} 

        { user.isClient && favouritesSelected && (
          <button className='myButton' onClick={() => handleDownloadImage(photo)}><i className="bi bi-download"></i></button>
        )}


        { !user.isClient && (
            <button className='image__action delete' onClick={handleDeletePicture} id={photo.id}> 
              <i className="bi bi-x-lg image__action__icon" id={photo.id}></i> 
              <span className='image__action__text' id={photo.id}> Supprimer </span>
            </button>
        )}
      </div>
      
  )};